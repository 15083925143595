import { Plugin, PluginKey, TextSelection } from "prosemirror-state";

import { getLink, getSelectionTextRange } from "../helpers";

export const pluginKey = new PluginKey("editLink");

export const defaultState = {
  active: false,
  href: "",
  title: "",
  editingLink: false,
};

/**
 * Controls the state for editing a link and detects when a link has been clicked.
 * The UI for editing links is handled separately in a component.
 * @returns ProseMirrorPlugin
 */
export default function editLink() {
  return new Plugin({
    key: pluginKey,

    state: {
      // Initialize the plugin's internal state.
      init() {
        return defaultState;
      },

      // Apply changes to the plugin state from a view transaction.
      apply(tr, prev) {
        const meta = tr.getMeta(pluginKey);
        const updatedState = meta?.updatedState || {};
        const next = {
          ...prev,
          ...updatedState,
        };

        return next;
      },
    },

    props: {
      // Sets the selection to the clicked on link and sets the plugin state to allow editing the link
      handleClick: (view) => {
        const { tr } = view.state;
        const link = getLink(view.state);

        if (link) {
          const { href, title } = link.attrs;
          const { from, to } = getSelectionTextRange(view.state);

          // Set the selection to the link node so that any editing will replace it
          tr.setSelection(TextSelection.create(view.state.doc, from, to));

          tr.setMeta(pluginKey, {
            updatedState: {
              active: true,
              editingLink: true,
              href,
              title,
            },
          });
          view.dispatch(tr);
        }
      },
    },
  });
}
