import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export default function placeholder({ text }: { text: string }) {
  return new Plugin({
    props: {
      decorations(state) {
        const decorations = [];

        if (state.doc.content.size === 2) {
          decorations.push(
            Decoration.node(0, 2, {
              class: "placeholder",
              "data-placeholder-text": text,
            })
          );

          return DecorationSet.create(state.doc, decorations);
        }
      },
    },
  });
}
