import { useCallback, useEffect, useState } from "react";

import useFeedUserFollow from "hooks/useFeedUserFollow";

import useAuth from "./useAuth";
import useFollows from "./useFollows";

export default function useFollow({ id }) {
  const { user } = useAuth();
  const { followingUsers, refetch } = useFollows(user?.uid);
  const [isUserFollowing, setIsUserFollowing] = useState();
  const [loading, setLoading] = useState(false);
  const { followUser, unfollowUser } = useFeedUserFollow();

  useEffect(() => {
    setIsUserFollowing(
      followingUsers.some((followingUser) => followingUser?.node?.id === id)
    );
  }, [followingUsers, id]);

  const toggleFollow = useCallback(async () => {
    setLoading(true);

    if (isUserFollowing) await unfollowUser({ variables: { id } });
    else await followUser({ variables: { id } });

    await refetch();
    setLoading(false);
  }, [followUser, id, isUserFollowing, refetch, unfollowUser]);

  return {
    isUserFollowing,
    loading,
    toggleFollow,
  };
}
