import { Schema } from "prosemirror-model";

import baseUrl from "lib/site/baseUrl";

export default new Schema({
  nodes: {
    doc: {
      content: "block+",
    },

    paragraph: {
      content: "inline*",
      group: "block",
      parseDOM: [{ tag: "p" }],
      toDOM() {
        return ["p", 0];
      },
    },

    ordered_list: {
      content: "list_item+",
      group: "block",
      attrs: { order: { default: 1 }, tight: { default: true } },
      parseDOM: [
        {
          tag: "ol",
          getAttrs(dom: any) {
            return {
              order: dom.hasAttribute("start") ? +dom.getAttribute("start") : 1,
              tight: dom.hasAttribute("data-tight"),
            };
          },
        },
      ],
      toDOM: function toDOM(node) {
        return ["ol", { start: node.attrs.order }, 0];
      },
    },

    bullet_list: {
      content: "list_item+",
      group: "block",
      attrs: { tight: { default: true } },
      parseDOM: [
        {
          tag: "ul",
          getAttrs(dom: any) {
            return { tight: dom.hasAttribute("data-tight") };
          },
        },
      ],
      toDOM: function toDOM() {
        return ["li", 0];
      },
    },

    list_item: {
      content: "paragraph block*",
      defining: true,
      parseDOM: [{ tag: "li" }],
      toDOM: function toDOM() {
        return ["li", 0];
      },
    },

    text: {
      group: "inline",
    },

    hard_break: {
      inline: true,
      group: "inline",
      selectable: false,
      parseDOM: [{ tag: "br" }],
      content: " ",
      toDOM: function toDOM() {
        return ["br"];
      },
    },

    mention: {
      attrs: {
        href: {},
        label: {},
      },
      inline: true,
      group: "inline",
      selectable: false,
      atom: true, // treat as leaf node (makes the cursor select the whole node so it can be backspaced through)
      parseDOM: [
        {
          tag: "a[href]",
          getAttrs(dom: any) {
            return {
              href: dom.getAttribute("href"),
              label: dom.getAttribute("label"),
            };
          },
        },
      ],
      toDOM: (node) => {
        return [
          "a",
          {
            class: "mention",
            href: baseUrl(node.attrs.href),
          },
          `@${node.attrs.label}`,
        ];
      },
    },
  },

  marks: {
    link: {
      attrs: {
        href: {},
        title: { default: null },
        target: { default: "_blank" },
        "data-edited-link": { default: false },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: "a[href]",
          getAttrs(dom: any) {
            return {
              href: dom.getAttribute("href"),
              title: dom.getAttribute("title"),
              target: "_blank",
              "data-edited-link": dom.hasAttribute("data-edited-link"),
            };
          },
        },
      ],
      toDOM: function toDOM(node) {
        return ["a", node.attrs];
      },
    },
  },
});
