import { styled } from "@mui/system";
import { EditorState, Selection } from "prosemirror-state";
import EditorView, { EditorProps } from "prosemirror-view";
import React, { Dispatch, RefObject, SetStateAction, useEffect } from "react";

import cls from "lib/common/cls";

import ProseMirror from "./useProsemirror/ProseMirror";

type Props = {
  state: EditorState;
  setState: Dispatch<SetStateAction<EditorState>>;
  editorProps?: EditorProps;
  className?: string;
};

const StyledProseMirror = styled(ProseMirror)({
  "& .ProseMirror": {
    outline: "none",
    whiteSpace: "break-spaces",
    overflowY: "auto",

    "& ol": {
      paddingLeft: 0,
      listStylePosition: "inside",
    },

    "& ul": {
      paddingLeft: 0,
      listStylePosition: "inside",
    },

    "& li p": {
      display: "inline-block",
      margin: "3px 0",
    },
  },
  "& .placeholder": {
    cursor: "text",
    position: "relative",
  },
  "& .placeholder[data-placeholder-text]:after": {
    color: "#808080",
    content: "attr(data-placeholder-text)",
    position: "absolute",
    top: 0,
  },
});

export type EditorViewRef = RefObject<{ view: EditorView }>;

const Editor = React.forwardRef(
  ({ className, state, setState, editorProps }: Props, ref: EditorViewRef) => {
    // Set the cursor to the end of the doc
    useEffect(() => {
      const tr = state.tr.setSelection(Selection.atEnd(state.doc));
      setState(() => state.apply(tr));
      ref?.current?.view?.focus();
      // Disabled as we only want this to run on initial render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <StyledProseMirror
        ref={ref}
        className={cls([className])}
        state={state}
        onChange={setState}
        {...editorProps}
      />
    );
  }
);

export default Editor;
