import { useMutation } from "@apollo/client";

import {
  USER_FOLLOW_MUTATION,
  USER_UNFOLLOW_MUTATION,
} from "graphql/user/mutations";

export default function useFeedUserFollow() {
  const [followUser, { loading: loadingFollow }] =
    useMutation(USER_FOLLOW_MUTATION);
  const [unfollowUser, { loading: loadingUnfollow }] = useMutation(
    USER_UNFOLLOW_MUTATION
  );

  return {
    followUser,
    loadingFollow,
    loadingUnfollow,
    unfollowUser,
  };
}
