import useMemberFeedData from "hooks/useMemberFeedData";

export default function useFollows(userId) {
  const { user, loading, refetch } = useMemberFeedData(userId);

  const groups =
    user?.followingGroups?.edges?.filter((group) => !group?.node?.deletedAt) ||
    [];
  const followingUsers = user?.followingUsers?.edges || [];
  const followerUsers = user?.followerUsers?.edges || [];

  return {
    followerUsers,
    followingUsers,
    groups,
    loading,
    refetch,
  };
}
