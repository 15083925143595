import { defaultMarkdownSerializer as customMarkdownSerializer } from "prosemirror-markdown";

customMarkdownSerializer.nodes = {
  ...customMarkdownSerializer.nodes,
  mention: (state, node) => {
    const href = state.esc(node.attrs.href);
    const label = state.esc(node.attrs.label);
    state.write(`[@${label}](${href})`);
  },
};

export default customMarkdownSerializer;
