import MarkdownIt, { Token } from "markdown-it";
import { MarkdownParser } from "prosemirror-markdown";

import markdownitMentionPlugin from "./markdownitMentionPlugin";
import schema from "../schema";

function listIsTight(tokens: Token[], idx: number) {
  let i = idx;
  while (++i < tokens.length) {
    if (tokens[i].type !== "list_item_open") {
      return tokens[i].hidden;
    }
  }
  return false;
}

/**
 * This is mostly the same as the defaulMarkdownParser exported from the
 * prosemirror-markdown package with the addition of the mention node type
 * and the markdownitMentionPlugin for parsing mentions. It parses markdown
 * and converts it into the prosemirror document model for use in the editor.
 */
const customMarkdownParser = new MarkdownParser(
  schema,
  MarkdownIt("commonmark", { html: false })
    .disable([
      "backticks",
      "emphasis",
      "entity",
      "blockquote",
      "code",
      "fence",
      "heading",
      "hr",
      "html_block",
      "lheading",
      "reference",
    ])
    .use(markdownitMentionPlugin),
  {
    paragraph: { block: "paragraph" },
    list_item: { block: "list_item" },
    bullet_list: {
      block: "bullet_list",
      getAttrs: (_, tokens, i) => ({ tight: listIsTight(tokens, i) }),
    },
    ordered_list: {
      block: "ordered_list",
      getAttrs: (tok, tokens, i) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        order: +tok.attrGet("start")! || 1,
        tight: listIsTight(tokens, i),
      }),
    },
    hardbreak: { node: "hard_break" },
    mention: {
      node: "mention",
      getAttrs: (tok) => ({
        href: tok.attrGet("href"),
        label: tok.attrGet("label") || null,
      }),
    },
    link: {
      mark: "link",
      getAttrs: (tok) => ({
        href: tok.attrGet("href"),
        title: tok.attrGet("title") || null,
      }),
    },
  }
);

export default customMarkdownParser;
