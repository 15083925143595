// matchAll isn't available on iOS 12.5 so this function works as a drop in replacement

// export default function matchAll(rx, str) {
//   if (String.prototype.matchAll) {
//     return str.matchAll(rx);
//   }

//   let cap = [];
//   const all = [];
//   // eslint-disable-next-line no-cond-assign
//   while ((cap = rx.exec(str)) !== null) all.push(cap);
//   return all;
// }

export default function matchAll(rx: RegExp, str: string) {
  if (!String.prototype.matchAll) {
    let cap: RegExpExecArray | null;
    const all = [];
    // eslint-disable-next-line no-cond-assign
    while ((cap = rx.exec(str)) !== null) all.push(cap);
    return all;
  }

  return str.matchAll(rx);
}
