import {
  chainCommands,
  exitCode,
  joinDown,
  joinUp,
  lift,
  selectParentNode,
  setBlockType,
  toggleMark,
} from "prosemirror-commands";
import { undo } from "prosemirror-history";
import { wrappingInputRule } from "prosemirror-inputrules";
import {
  DOMSerializer,
  Mark,
  MarkType,
  Node,
  NodeType,
} from "prosemirror-model";
import {
  liftListItem,
  sinkListItem,
  splitListItem,
  wrapInList,
} from "prosemirror-schema-list";
import { Command, EditorState, TextSelection } from "prosemirror-state";
import { DecorationAttrs } from "prosemirror-view";
import { Dispatch, SetStateAction } from "react";

import customMarkdownSerializer from "components/shared/inputs/Editor/markdown/customMarkdownSerializer";

import schema from "./schema";

/**
 * Determines if a ProseMirror doc Node has content
 * @param {ProseMirrorNode} doc
 * @returns boolean
 */
export function docHasContent(doc: Node) {
  const hasContent =
    doc.childCount >= 1 &&
    doc.firstChild &&
    (doc.firstChild.isTextblock || doc.firstChild.textContent) &&
    doc.firstChild.content.size > 0;
  return hasContent;
}

/**
 * Determines if a ProseMirror doc Node has a link mark
 * @param {ProseMirrorNode} doc
 * @returns boolean
 */
export function docHasLink(doc: Node) {
  return doc.rangeHasMark(0, doc.content.size, schema.marks.link);
}

/**
 * Serializes a ProseMirror doc Node into a markdown string
 * @param {ProseMirrorNode} doc
 * @returns string
 */
export function getContentAsMarkdown(doc: Node) {
  const markdown = customMarkdownSerializer.serialize(doc, {
    tightLists: true,
  });
  // Prosemirrors markdown serializer will add backslashes to escape any characters
  // that could be interpreted as markdown if they aren't part of its schema. It will
  // also add backslashes to represent line breaks. This is a problem in the mobile app
  // because its markdown parser is currently ignoring backslash escaping. We support a
  // very small subset of markdown so there shouldn't be any issues with stripping the
  // back slashes from the output markdown.
  return markdown.replaceAll("\\", "");
}

/**
 * Serializes a ProseMirror doc Node into an HTML string
 * @param {ProseMirrorNode} doc
 * @returns string
 */
export function getContentAsHTML(doc: Node) {
  const contentDocFragment = DOMSerializer.fromSchema(schema).serializeFragment(
    doc.content
  );
  const containerNode = document.createElement("div");
  containerNode.appendChild(contentDocFragment);
  return containerNode.innerHTML;
}

export function clearAllContent(state: EditorState) {
  const { tr } = state;
  tr.delete(0, state.doc.nodeSize - 2);
  return tr;
}

/**
 * Determines if the current selection has a mark of the provided type
 * @param {ProseMirrorEditorState} state
 * @param {ProseMirrorMarkType} type
 * @returns boolean
 */
export function isMarkActive(state: EditorState, type: MarkType) {
  const { selection } = state;
  const { from, $from, to, empty } = selection;

  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks());
  }
  return state.doc.rangeHasMark(from, to, type);
}

/**
 * Determines if the current selection has a bold/strong mark
 * @param {ProseMirrorEditorState} state
 * @returns boolean
 */
export function isBold(state: EditorState) {
  return isMarkActive(state, schema.marks.strong);
}

/**
 * Determines if the current selection has a italic/em mark
 * @param {ProseMirrorEditorState} state
 * @returns boolean
 */
export function isItalic(state: EditorState) {
  return isMarkActive(state, schema.marks.em);
}

/**
 * Determines if the current selection has a link mark
 * @param {ProseMirrorEditorState} state
 * @returns boolean
 */
export function isLink(state: EditorState) {
  return isMarkActive(state, schema.marks.link) as boolean &
    Mark & { title: string; href: string };
}

/**
 * Toggles the bold/strong mark on the current selection OR stored
 * marks if there is no selection
 * @param {ProseMirrorEditorState} state
 * @param {function} setState
 * @returns boolean
 */
export function toggleBold(
  state: EditorState,
  setState: Dispatch<SetStateAction<EditorState>>
) {
  return toggleMark(schema.marks.strong)(state, (tr) =>
    setState(state.apply(tr))
  );
}

/**
 * Toggles the italic/em mark on the current selection OR stored
 * marks if there is no selection
 * @param {ProseMirrorEditorState} state
 * @param {function} setState
 * @returns boolean
 */
export function toggleItalic(
  state: EditorState,
  setState: Dispatch<SetStateAction<EditorState>>
) {
  return toggleMark(schema.marks.em)(state, (tr) => setState(state.apply(tr)));
}

/**
 * Inserts a specified list node type at the current selection
 * @param {ProseMirrorEditorState} state
 * @param {function} setState
 * @param {ProseMirrorNodeType} node
 * @returns boolean
 */
export function insertListNode(
  state: EditorState,
  setState: Dispatch<SetStateAction<EditorState>>,
  node: NodeType
) {
  return wrapInList(node)(state, (tr) => setState(state.apply(tr)));
}

/**
 * Inserts a bullet list node type at the current selection
 * @param {ProseMirrorEditorState} state
 * @param {function} setState
 * @returns boolean
 */
export function toggleBulletList(
  state: EditorState,
  setState: Dispatch<SetStateAction<EditorState>>
) {
  return insertListNode(state, setState, schema.nodes.bullet_list);
}

/**
 * Inserts an ordered list node type at the current selection
 * @param {ProseMirrorEditorState} state
 * @param {function} setState
 * @returns boolean
 */
export function toggleOrderedList(
  state: EditorState,
  setState: Dispatch<SetStateAction<EditorState>>
) {
  return insertListNode(state, setState, schema.nodes.ordered_list);
}

/**
 * Gets the text from the current selection range
 * @param {ProseMirrorEditorState} state
 * @returns string
 */
export function getSelectionText(state: EditorState) {
  const { selection } = state;
  const { from, to } = selection;
  if (from < to && to < state.doc.nodeSize) {
    return state.doc.textBetween(from, to, "\n", "\n");
  }
  return "";
}

/**
 * Normalizes urls with the appropriate scheme if it's missing
 * @param {string} uri
 * @returns string
 */
export function normalizeUrl(uri: string) {
  if (
    ["http://", "https://", "mailto:"].some((prefix) => uri.startsWith(prefix))
  ) {
    return uri;
  }
  return uri.includes("@") ? `mailto:${uri}` : `http://${uri}`;
}

/**
 * Get's all of the marks of the specified type within the specified range
 * @param {ProseMirrorNode} doc
 * @param {integer} from
 * @param {integer} to
 * @param {string} typeName
 * @returns array
 */
export function getMarksByTypeBetween(
  doc: Node,
  from: number,
  to: number,
  typeName: string
) {
  const marks: any[] = [];

  if (from < to) {
    doc.nodesBetween(from, to, (node, pos) => {
      marks.push(
        ...node.marks.map((mark) => ({
          from: pos,
          to: pos + node.nodeSize,
          mark,
        }))
      );
    });
  }

  return marks.filter(({ mark }) => mark.type.name === typeName);
}

/**
 * Finds all the children of the specified node type within the specified range
 * @param {ProseMirrorNode} doc
 * @param {integer} from
 * @param {integer} to
 * @param {string} typeName
 * @returns array
 */
export function findChildrenOfNodeTypeBetween(
  doc: Node,
  from: number,
  to: number,
  typeName: string
) {
  const nodesWithPos: any[] = [];

  doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.name === typeName) {
      nodesWithPos.push({ node, pos });
    }
  });

  return nodesWithPos;
}

/**
 * Gets the link mark at the current selection or false if there is no link
 * @param {ProseMirrorEditorState} state
 * @returns ProseMirrorMark || boolean
 */
export function getLink(state: EditorState) {
  if (isLink(state)) {
    const { selection } = state;
    const { from, to } = selection;

    if (from < to) {
      const marks = getMarksByTypeBetween(state.doc, from, to, "link");
      return marks?.[0]?.mark || false;
    }

    const linkNode = state.doc.nodeAt(from);
    return linkNode?.marks?.[0];
  }

  return false;
}

/**
 * Replaces the current selection with a link mark and returns the transaction
 * @param {ProseMirrorEditorState} state
 * @param {object} attrs
 * @returns ProseMirrorTransaction
 */
export function insertLink(state: EditorState, attrs: DecorationAttrs) {
  const { tr } = state;
  const link = schema.marks.link.create(attrs);
  const node = schema.text(attrs.title || "").mark([link]);
  tr.replaceSelectionWith(node, false);
  return tr;
}

/**
 * Gets the range of the text node at the current selection. This is useful for
 * getting the start and end points of a marks text
 * @param {ProseMirrorEditorState} state
 * @returns object
 */
export function getSelectionTextRange(state: EditorState) {
  const { selection } = state;
  const { $from } = selection;

  const selectionNode = $from.parent.childAfter($from.parentOffset);
  const from = $from.pos - $from.textOffset;
  const to = from + (selectionNode?.node?.nodeSize || 0);

  return { from, to };
}

/**
 * Removes a link mark from the current selection
 * @param {ProseMirrorEditorState} state
 * @returns ProseMirrorTransaction
 */
export function removeLink(state: EditorState) {
  const { tr } = state;
  const { from, to } = getSelectionTextRange(state);
  if (to < state.doc.nodeSize) {
    tr.removeMark(from, to, schema.marks.link);
  }
  return tr;
}

/**
 * Sets the cursor to the end of the current selection. Returns the transaction
 * which will need to be added then need to be added to the state separately
 * @param {ProseMirrorEditorState} state
 * @returns ProseMirrorTransaction
 */
export function setCursorToSelectionEnd(state: EditorState) {
  const { tr } = state;
  const { selection } = state;
  tr.setSelection(TextSelection.create(tr.doc, selection.to, selection.to));
  return tr;
}

/**
 * Given a list node type, returns an input rule that turns a number followed by
 * a dot at the start of a textblock into an ordered list.
 * @param {ProseMirrorNodeType} nodeType
 * @returns ProseMirrorInputRule
 */
export function orderedListRule(nodeType: NodeType) {
  return wrappingInputRule(
    /^(\d+)\.\s$/,
    nodeType,
    (match) => ({ order: +match[1] }),
    (match, node) => node.childCount + node.attrs.order === +match[1]
  );
}

/**
 * Builds a keymap with our schema for passing into the prosemirror keymap plugin.
 * @param {object} mapKeys
 * @returns object
 */
export function buildKeymap(mapKeys?: { [key: string]: Command }) {
  const keys: { [key: string]: Command } = {};
  const { strong, em } = schema.marks;
  // eslint-disable-next-line camelcase
  const { bullet_list, ordered_list, list_item, paragraph, hard_break } =
    schema.nodes;

  function bind(key: string, cmd: Command) {
    if (mapKeys) {
      const mapped = mapKeys[key];
      if (!mapped) return;
      // if (mapped) key = mapped
    }
    keys[key] = cmd;
  }

  bind("Alt-ArrowUp", joinUp);
  bind("Alt-ArrowDown", joinDown);
  bind("Mod-BracketLeft", lift);
  bind("Escape", selectParentNode);

  if (strong) {
    bind("Mod-b", toggleMark(strong));
    bind("Mod-B", toggleMark(strong));
  }
  if (em) {
    bind("Mod-i", toggleMark(em));
    bind("Mod-I", toggleMark(em));
  }

  // eslint-disable-next-line camelcase
  if (bullet_list) {
    bind("Shift-Ctrl-8", wrapInList(bullet_list));
    bind("Shift-Mod-8", wrapInList(bullet_list));
  }
  // eslint-disable-next-line camelcase
  if (ordered_list) {
    bind("Shift-Ctrl-7", wrapInList(ordered_list));
    bind("Shift-Mod-7", wrapInList(bullet_list));
  }

  // eslint-disable-next-line camelcase
  if (hard_break) {
    const cmd = chainCommands(exitCode, function addBreak(state, dispatch) {
      if (!dispatch) return false;

      dispatch(
        state.tr.replaceSelectionWith(hard_break.create()).scrollIntoView()
      );
      return true;
    });
    bind("Shift-Enter", cmd);
    bind("Mod-Enter", cmd);
    bind("Alt-Enter", cmd);
    bind("Shift-Enter", cmd);
  }
  // eslint-disable-next-line camelcase
  if (list_item) {
    bind("Enter", splitListItem(list_item));
    bind("Mod-[", liftListItem(list_item));
    bind("Mod-]", sinkListItem(list_item));
  }
  if (paragraph) {
    bind("Shift-Ctrl-0", setBlockType(paragraph));
  }

  bind("Mod-z", undo);

  return keys;
}
