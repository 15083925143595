import { baseKeymap } from "prosemirror-commands";
import { history } from "prosemirror-history";
import { inputRules } from "prosemirror-inputrules";
import { keymap } from "prosemirror-keymap";
import { EditorState, Plugin } from "prosemirror-state";
import { Dispatch, SetStateAction } from "react";
import { useProseMirror } from "use-prosemirror";

import { buildKeymap, orderedListRule } from "./helpers";
import markdownParser from "./markdown/customMarkdownParser";
import schema from "./schema";

type Props = {
  content: string;
  plugins: Plugin[];
};

// Keymap order is important, keymaps higher in the array take precedence
const corePlugins = [
  history(),
  keymap(buildKeymap()),
  keymap(baseKeymap),
  inputRules({ rules: [orderedListRule(schema.nodes.ordered_list)] }),
];

export default function useEditor({
  content,
  plugins = [],
}: Props): [EditorState, Dispatch<SetStateAction<EditorState>>] {
  // Other possible config - https://prosemirror.net/docs/ref/#state.EditorState%5Ecreate
  const [state, setState] = useProseMirror({
    schema,
    doc: content && markdownParser.parse(content),
    // plugins must come before corePlugins in case there is a plugin that needs to
    // override the corePlugins e.g. the keymap plugin
    plugins: [...plugins, ...corePlugins],
  });

  return [state, setState];
}
