const safeTLDs = [
  // US TLDs
  "com",
  "org",
  "net",
  "edu",
  "gov",
  "mil",
  // generic domains
  "biz",
  "blog",
  "dev",
  "gle",
  "info",
  "jobs",
  // common country codes
  "ai",
  "as",
  "au",
  "ca",
  "co",
  "eu",
  "gl",
  "io",
  "ly",
  "me",
  "tv",
  "uk",
  "us",
];

export default function parseLinkMatch(match: RegExpMatchArray) {
  let link = {};
  const [matchedText, email, url, proto, tld, urlPath] = match;

  if (email) {
    link = {
      index: match.index,
      type: "email",
      href: `mailto:${email}`,
      matchedText,
    };
  } else if (url) {
    // only auto-parse URLs if they seem realistic
    // Don't want to parse "end of sentence.New sentence" as a URL!
    if (
      proto ||
      url.startsWith("www.") ||
      urlPath ||
      safeTLDs.includes(tld.toLowerCase())
    ) {
      link = {
        index: match.index,
        type: "link",
        href: proto ? url : `http://${url}`,
        matchedText,
      };
    }
  }

  return link;
}
