import { gql } from "@apollo/client";

import {
  USER_FRAGMENT,
  USER_PROFILE_FRAGMENT,
  USER_WITH_FOLLOW_DATA_FRAGMENT,
} from "graphql/user/fragments";

export const USERS_BULK = gql`
  query usersBulk($input: UsersBulkInput!) {
    usersBulk(input: $input) {
      id
      snapshot {
        id
        name
        avatar
      }
    }
  }
`;

export const USER_MEMBERS_QUERY = gql`
  query userMembers($communityCode: String, $query: String) {
    userMembers(
      input: { isActive: true, communityCode: $communityCode, query: $query }
      first: 20
    ) {
      edges {
        node {
          snapshot {
            id
            name
          }
        }
      }
    }
  }
`;

export const USER_FEED_DATA_QUERY = gql`
  query userFeedData($id: ID) {
    user(id: $id) {
      ...User_With_Follow_Data_Fragment
    }
  }
  ${USER_WITH_FOLLOW_DATA_FRAGMENT}
`;

export const USER_PROFILE_QUERY = gql`
  query userProfile($id: ID) {
    user(id: $id) {
      id
      name {
        first
        fullName
      }
      profile {
        ...UserProfile_Fragment
      }
      type
      snapshot {
        joinedAt
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const USER_BASIC_QUERY = gql`
  query userBasic($id: ID) {
    user(id: $id) {
      ...User_Fragment
    }
  }
  ${USER_FRAGMENT}
`;
