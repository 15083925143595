import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { USER_FEED_DATA_QUERY } from "graphql/user/queries";

export default function useMemberFeedData(id) {
  const [memberData, setMemberData] = useState();
  const [getMemberData, { called, error, loading }] =
    useLazyQuery(USER_FEED_DATA_QUERY);

  const onCompleted = (newData) => {
    const clonedData = JSON.parse(JSON.stringify(newData));
    // Remove self from follower/following data
    clonedData.user.followerUsers.edges =
      clonedData?.user?.followerUsers?.edges?.filter(
        (followerUser) => followerUser?.node?.id !== id
      );
    clonedData.user.followingUsers.edges =
      clonedData?.user?.followingUsers?.edges?.filter(
        (followingUser) => followingUser?.node?.id !== id
      );

    setMemberData(clonedData);
  };

  useEffect(() => {
    getMemberData({
      variables: { id },
      onCompleted,
      fetchPolicy: "network-only",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    user: memberData?.user ?? {},
    loading: !called || loading,
    error,
    refetch: () =>
      getMemberData({
        variables: { id },
        onCompleted,
        fetchPolicy: "network-only",
      }),
  };
}
