import { Plugin, PluginKey } from "prosemirror-state";

import matchAll from "lib/common/matchAll";

import parseLinkMatch from "./parseLinkMatch";
import {
  findChildrenOfNodeTypeBetween,
  getMarksByTypeBetween,
} from "../../helpers";
import schema from "../../schema";

const linkRegex =
  /(?:(?:^|[ \t\r\n])(?:([a-z0-9][\w.+~-]+@(?:[a-z0-9](?:[\w-]*[a-z0-9])?\.)+[a-z]{2,})|((https?:\/\/)?(?:[a-z0-9](?:[\w-]*[a-z0-9])?\.)+([a-z]{2,})(?::\d{2,5})?([/?#][/\w.?&=%#+-]*)?)))/gi;

export const pluginKey = new PluginKey("autoLink");

/**
 * Detects urls as they're typed into the editor and turns them into links.
 * As the user types it will check all of the existing auto links to see if
 * they should be removed and it will also check the text to see if any new
 * links should be added.
 * @returns ProseMirrorPlugin
 */
export default function autoLink() {
  return new Plugin({
    key: pluginKey,

    state: {
      init() {
        return {
          addLinks: [],
          removeLinks: [],
        };
      },

      apply(tr, prev) {
        const next: any = { ...prev };
        const { selection } = tr;
        const { empty } = selection;

        // If there is no selection
        if (empty) {
          // Find the links to remove
          const removeLinks: any[] = [];
          getMarksByTypeBetween(tr.doc, 0, tr.doc.nodeSize - 2, "link").forEach(
            (mark) => {
              const markText = tr.doc.textBetween(
                mark.from,
                mark.to,
                undefined,
                "\n"
              );
              const match =
                markText.match(linkRegex) ||
                mark.mark.attrs.href.match(linkRegex);
              const isEditedLink =
                mark?.mark?.attrs?.["data-edited-link"] === true;

              // Only remove the link mark if it no longer matches the link regex
              // and if it hasn't been manually edited
              if (!match && !isEditedLink) {
                removeLinks.push({ range: { from: mark.from, to: mark.to } });
              }
            }
          );

          // Find the links to add
          const addLinks: any[] = [];
          findChildrenOfNodeTypeBetween(
            tr.doc,
            0,
            tr.doc.nodeSize - 2,
            "paragraph"
          ).forEach((textNode) => {
            // Add an empty space placeholder for leaf nodes (e.g. mentions) so that
            // the position can be calculated correctly
            const textContent = tr.doc.textBetween(
              textNode.pos,
              textNode.pos + textNode.node.nodeSize,
              undefined,
              " "
            );

            const matches = [...matchAll(linkRegex, textContent)];

            matches.forEach((match) => {
              const parsedLinkMatch: any = parseLinkMatch(match);

              if (parsedLinkMatch?.matchedText?.[0]) {
                const matchStartsWithSpace = /^[\s\0]?$/.test(
                  parsedLinkMatch.matchedText[0]
                );
                const extraSpaceLength = matchStartsWithSpace ? 1 : 0;
                // + 1 to account for starting from the position of the paragraph node
                const from = textNode.pos + 1 + match.index + extraSpaceLength;
                const to = from + parsedLinkMatch.matchedText.trim().length;

                addLinks.push({
                  range: {
                    from,
                    to,
                  },
                  ...parsedLinkMatch,
                });
              }
            });
          });

          next.addLinks = addLinks;
          next.removeLinks = removeLinks;
        }

        return next;
      },
    },

    appendTransaction: (transactions: any, prevState: any, nextState: any) => {
      const { tr } = nextState;
      const nextPluginState = nextState.autoLink$;
      const changed = !prevState.doc.eq(nextState.doc);

      if (changed) {
        nextPluginState.removeLinks.forEach((link: any) => {
          const { range } = link;
          const { from, to } = range;

          if (
            to <= nextState.doc.content.size &&
            nextState.doc.rangeHasMark(from, to, schema.marks.link)
          ) {
            tr.removeMark(from, to, schema.marks.link);
          }
        });

        nextPluginState.addLinks.forEach((link: any) => {
          const { range, href } = link;
          const { from, to } = range;

          if (to <= nextState.doc.content.size) {
            tr.addMark(from, to, schema.marks.link.create({ href }));
          }
        });
      }

      return tr;
    },
  });
}
