// Modified from https://github.com/quartzy/markdown-it-mentions

import MarkdownIt, { Token } from "markdown-it";

type MentionToken = Token & {
  type: "mention";
  mention: {
    href: string;
    label: string;
  };
};

function renderMention(tokens: MentionToken[], idx: number) {
  return `<span class="mention" href="${tokens[idx].mention.href}">${tokens[idx].mention.label}</span>`;
}

function parseMentions(state) {
  const matcher = /^@/;

  state.tokens.forEach((blockToken: Token) => {
    if (blockToken.type !== "inline") return;

    const { children } = blockToken;

    children.forEach((token: Token, idx: number) => {
      // Back out if we're near the end of the token array
      if (idx + 3 > children.length) return;

      // Grab the next four tokens that could potentially construct a mention
      const tokenGroup = children.slice(idx, idx + 4);

      const matchToken = tokenGroup[0];
      const openToken = tokenGroup[1];
      let textToken = tokenGroup[2];
      let closeToken = tokenGroup[3] || { type: "" };

      // Compensate for when the link has no label
      if (textToken.type === "link_close") {
        closeToken = textToken;
        textToken = null;
      }

      // Back out if we're not dealing with a mention
      if (matchToken.type !== "text") return;
      if (openToken.type !== "link_open") return;
      if (closeToken.type !== "link_close") return;
      if (!matcher.test(textToken.content)) return;

      // Replace the "link_open" with a single "mention" token
      openToken.type = "mention";

      const labelWithAtSymbolRemoved = textToken
        ? textToken.content.substring(1)
        : "";

      // attrs will already contain the href so we just need to add the label
      openToken.attrs.push(["label", labelWithAtSymbolRemoved]);

      // Remove the "text" and "link_close" tokens
      children.splice(idx + 2, textToken ? 2 : 1);
    });

    blockToken.children = children;
  });
}

export default function markdownitMentionPlugin(md: MarkdownIt) {
  md.renderer.rules.mention = renderMention;
  md.core.ruler.after("inline", "mention", parseMentions);
}
